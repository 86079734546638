import $ from 'jquery';
import 'moment/moment';

import 'chart.js/dist/Chart.css';
import 'chart.js/dist/Chart';

import '../styles/chart.css';

(
    ($) => {
        $(document).ready(function () {
            // const colorPurple = [rgb(120, 110, 240), rgb(200, 195, 255), rgb(255, 255, 255)];
            // const colorGreen = [rgb(110, 148, 56), rgb(85, 114, 43), rgb(53, 76, 20)];

            function statisticsCounter(id, parent) {
                const item6Label = parent.find('.item6_label');

                const chartLabels = [];
                item6Label.each(function () {
                    chartLabels.push($(this).find('.item6_data').html());
                });

                const chartData = [];
                item6Label.each(function () {
                    chartData.push($(this).attr('data'));
                });

                var item6DataTotal = 0;
                for (var i = 0; i < chartData.length; i++) {
                    item6DataTotal += parseInt(chartData[i]);
                }
                parent.find('.item6-data_total').html(item6DataTotal);

                const chartColor = [];
                item6Label.each(function () {
                    chartColor.push($(this).attr('background-color'));
                });

                item6Label.each(function () {
                    $(this).find('.item6_circule').css('backgroundColor', $(this).attr('background-color'));
                });

                const chartOptions = {
                    type: 'doughnut',
                    data: {
                        labels: chartLabels,
                        datasets: [{
                            data: chartData,
                            backgroundColor: chartColor,
                            borderWidth: 0,
                        }],
                    },
                    options: {
                        legend: false,
                        maintainAspectRatio: false,
                        aspectRatio: 1,
                        cutoutPercentage: 80,
                    },
                };
                new Chart(id, chartOptions);
            }

            function statisticsCounterIntersection() {
                // let options = {
                //     root: null,
                //     rootMargin: '0px',
                //     threshold: 0.5
                // };

                const ids = $(".item6-chart[id^='statistics-chart']");

                // let observer = new IntersectionObserver((entries) => {
                // entries.forEach(entry => {
                //     if (entry.isIntersecting) {
                // if (!$(entries).hasClass('activated')) {
                //     $(entries).addClass('activated');
                ids.each(function () {
                    statisticsCounter($(this), $(this).parents('.item6'));
                });
                // }
                // }
                // });
                // }, options);
                // observer.observe($('#statistics'));
            }

            statisticsCounterIntersection();
        });
    }
)
(jQuery);
